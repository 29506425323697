<template>
  	<div class="order-detail page">
      <div class="bg-1"></div>
		<div class="page-content">
      <div class="head">
        <div class="head-left">
          {{ orderInfo.orderStatus === 1 ? orderHeadName.name.startText : orderHeadName.name.overText}}
        </div>
        <div class="head-right">
         <img src="@/assets/img/index/logo_New_2.png" alt="">
        </div>
      </div>
			<div class="order-detail-content">
			<div class="client">
				<text class="client-key">{{ orderHeadName.thirdName }}:</text>
					<text class="client-val">{{ orderInfo.thirdName }}</text>
			</div>
			<div class="order-detail-content-price">
				<div class="num wy-num">{{ totalNum.total }}/{{ totalNum.price }}</div>
				<div class="order-detail-content-text">
					数量/金额(元)
				</div>
			</div>
		</div>
		<div class="product-list">
      <div class="shelf-goods-item" v-for="( info , index ) in tableData" :key="index">
      <div class="goods-item">
        <img :src="info.img" class="goods-item-img"/>
        <div class="goods-item-info">
          <div class="name"
            >{{ info.productName }} <text>/{{ info.unitName }}</text>
          </div>
          <div class="model-name">
            <div class="model-name-item">{{ info.modelName }}</div>
          </div>
        </div>
      </div>
      <div class="label-info">
        <div class="brand label-info-item">
          <span class="key">产品品牌：</span>
          <span class="val">{{ info.brandName || "--" }}</span>
        </div>
        <div class="price label-info-item">
          <span class="key">条码：</span>
          <span class="val">{{ info.encoded?.[0] || "--" }}</span>
        </div>
        <div class="price label-info-item">
          <span class="key">单价（元）：</span>
          <span class="val">{{ info.price || "--" }}</span>
        </div>
        <div class="label-info-item label-num">
          <div class="label">
            <span class="key">数量/小计(元)：</span>
            <span class="num">{{ isOver ? info.realityCount : info.count }}/{{ isOver ? $filters.toYuan(info.realityTotalPrice, '元') : $filters.toYuan(info.totalPrice, '元') }}</span>
          </div>
        </div>
      </div>
    </div>
		</div>
		</div>
	</div>
</template>

<script setup>
import {computed,onMounted, onUnmounted, getCurrentInstance, nextTick, reactive, ref} from "vue";
import {convertCurrency, reactiveAssign, resetGoodsList} from "@utils/tool";
import {useRoute} from "vue-router";
import {isOutOrder, orderConfig} from '@/views/print/config'
import {orderGoodsList, orderListDeatil} from "@utils/api/order";
import moment from "moment";
import {getUnitName} from "@utils/tool/orderTools";
import useH5 from "@utils/hooks/useH5.js";
import Logo from "../components/logo.vue";
const route = useRoute();
useH5()
const {query} = route;
const {proxy} = getCurrentInstance();
const tableData = ref([]);

const orderHeadName = reactive({
  name: {},
  stockName: "",
  thirdName: '',
  time: "",
  timeDesc: {}
})
const orderInfo = reactive({
  name: '',
  stockName: "",
  thirdName: '',
  time: "",
  orderSn: '',
  orderStatus: 0,
})
const isOver = computed(() => {
  return orderInfo.orderStatus === 1
})
function setOrder(orderType) {
  let obj = orderConfig[orderType]
  orderHeadName.name = obj.name
  orderHeadName.stockName = obj.stockName
  orderHeadName.thirdName = obj.thirdName
  orderHeadName.timeDesc = obj.timeDesc
}

setOrder(query.orderType)

const getGoodsDetail = () => {
  getOrder()
}

const totalNum = computed(() => {
  let total = 0
  let price = 0
  tableData.value.forEach(item => {
    total += (isOver.value ? item.realityCount : item.count || 0)
    price += (isOver.value ? item.realityTotalPrice : item.totalPrice || 0)
  })
  return {
    total,
    price: proxy.$filters.toYuan(price, '元'),
    big: convertCurrency(proxy.$filters.toYuan(price, '元'))
  }
})

const getOrder = () => {
  orderListDeatil({
    orderSn: query.orderSn,
    orderType: query.orderType,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data?.time) {
      res.data.time = moment(res.data.time).format("YYYY年MM月DD日HH时mm分");
    }
    if (res.data?.stockTime) {
      res.data.stockTime = moment(res.data.stockTime).format("YYYY年MM月DD日HH时mm分");
    }
    reactiveAssign(orderInfo, res.data);
    getGoodsList()
  })
}

getGoodsDetail()

const getGoodsList = () => {
  orderGoodsList({
    orderSn: query.orderSn,
    orderType: query.orderType,
    orderStatus: orderInfo.orderStatus,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || [];
    tableData.value = resetGoodsList(list, (item) => {
      item.orderType = query.orderType
    });
  });
};

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped src="./orderDetail.scss"></style>