/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-04 15:43:48
 * @FilePath: /aurora-carton-web/src/utils/api/order.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../http'
import {managerUrl} from '../api'

//订单列表
export function orderList(data){
  return request({
    url:managerUrl + "/carton/order/list",
    method:'post',
    data
  })
}

//订单列表
export function orderListDeatil(data){
  return request({
    url:managerUrl + "/carton/order/detail",
    method:'post',
    data
  })
}


//新增订单 编辑订单
export function orderAdd(data){
  return request({
    url:managerUrl + "/carton/order/add",
    method:'post',
    data
  })
}

//订单商品列表
export function orderGoodsList(data){
  return request({
    url:managerUrl + "/carton/order/product/list",
    method:'post',
    data
  })
}

//订单状态数量统计
export function orderStatusCensus(data){
  return request({
    url:managerUrl + "/carton/order/census",
    method:'post',
    data
  })
}

//订单业务商品搜索列表
export function orderGoodsSearch(data){
  return request({
    url:managerUrl + "/carton/order/product/search",
    method:'post',
    data,
    showLoading: false
  })
}

//删除订单
export function orderDel(data){
  return request({
    url:managerUrl + "/carton/order/del",
    method:'post',
    data
  })
}

//完成订单接口
export function orderComplete(data){
  return request({
    url:managerUrl + "/carton/order/complete",
    method:'post',
    data
  })
}

//入库类型列表
export function otherOrderTypeList(data){
  return request({
    url:managerUrl + "/carton/order/extend/type/list",
    method:'post',
    data
  })
}

//新增拓展其他入出库类型
export function otherOrderTypeAdd(data){
    return request({
        url:managerUrl + "/carton/order/extend/type/add",
        method:'post',
        data
    })
}

//修改拓展类型信息
export function otherOrderTypeEdit(data){
    return request({
        url:managerUrl + "/carton/order/extend/type/edit",
        method:'post',
        data
    })
}

//删除扩展类型
export function otherOrderTypeDel(data){
    return request({
        url:managerUrl + "/carton/order/extend/type/del",
        method:'post',
        data
    })
}
//订单编号对应订单信息
export function orderCodeDetail(data){
  return request({
    url:managerUrl + "/carton/order/equal/info",
    method:'post',
    data
  })
}

//对应信息产品列表
export function orderCodeProductList(data){
  return request({
    url:managerUrl + "/carton/order/product/equal/list",
    method:'post',
    data
  })
}

//产品对应
export function productInfoEqual(data){
  return request({
    url:managerUrl + "/carton/product/info/equal/to",
    method:'post',
    data
  })
}
//产品解除对应
export function productInfoEqualRelieve(data){
  return request({
    url:managerUrl + "/carton/product/info/equal/relieve",
    method:'post',
    data
  })
}