import { onMounted, onUnmounted } from "vue";
import {setRem ,addMeta ,removeMeta} from "@utils/tool";
import {useEventListener} from "@utils/tool/event";
export default function useH5() {
  useEventListener(window, 'resize', setRem)
  onMounted(() => {
    addMeta('viewport',"width=device-width, initial-scale=1",'metaPhone')
    setRem()
  })
  onUnmounted(() => {
    document.documentElement.style.fontSize = '14px';
    removeMeta('metaPhone')
  })
}